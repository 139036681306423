// src/services/postService.js
import axios from 'axios';

const api = axios.create({
  baseURL: 'http://localhost:1337/api', // Update this to the deployed URL when in production
});

export async function getPosts() {
  const response = await api.get('/posts');
  return response.data.data; 
}

export async function getPost(id) {
  const response = await api.get(`/posts/${id}`);
  return response.data.data;
}

export async function createPost(post) {
  const response = await api.post('/posts', { data: post });
  return response.data.data;
}
