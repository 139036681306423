<template>
    <div class=" sm:ml-2 lg:ml-12" ref="observerElement">

        <transition name="slide-up" appear v-if="isVisible">
            <div class=" font-rockinsoda text-navy text-5xl mt-32 text-center lg:text-left lg:text-6xl"  >
                <p>2024 SELECTED VENTURES</p>
            </div>
        </transition>

        <div class="grid grid-cols-2 mr-4 ml-4 lg:ml-0 lg:mr-12 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5  mt-6 ">
            <ul v-for="startup in startups" :key="startup.name"
                class=" border border-lightTangerine  p-4  h-[28vh]  ">
                <div id="startupVentDiv" class="flex items-center justify-center">
                    <li>
                        <router-link :to="{
                            name: 'VenturesDescPage',
                            params: { name: encodeURIComponent(startup.name) },
                        }">
                            <transition name="fade-slide-up" appear v-if="isVisible">
                                <img :src="startup.logo" alt="Logo" class="w-full h-12 mt-6  mb-4" />
                            </transition>

                            <transition name="fade-slide-right" appear v-if="isVisible">
                                <h3 class="text-lg text-center font-gramatika text-navy text-lg mt-4 ">{{ startup.name
                                    }}
                                </h3>
                            </transition>

                        </router-link>
                    </li>
                </div>

            </ul>

        </div>


    </div>

</template>

<script>
export default {

    name: 'FilteredVentures',
    data() {

        return {

            startups: [
                // 2024 venture .................   
                { name: 'AASAP', logo: require('../assets/nsrh/aasap.svg') },
                { name: 'ARAME', logo: require('../assets/nsrh/arame.svg') },
                { name: 'DawaHealth', logo: require('../assets/nsrh/dawa.svg') },
                { name: 'GRIT', logo: require('../assets/nsrh/grit.svg') },
                { name: 'JoCare', logo: require('../assets/nsrh/jocare.svg') },

                { name: 'LUNA', logo: require('../assets/nsrh/luna.svg') },
                { name: 'Malaica', logo: require('../assets/nsrh/malaica.svg') },
                { name: 'SMA', logo: require('../assets/nsrh/mother.svg') },
                { name: 'T.T.Y.M Global', logo: require('../assets/nsrh/talk.svg') },
                { name: 'Natal Cares', logo: require('../assets/nsrh/natal.svg') },

                { name: 'TenaSeb', logo: require('../assets/nsrh/tena.svg') },
                { name: 'SophieBOT', logo: require('../assets/nsrh/sophie.svg') },
                { name: 'MDKrLabs', logo: require('../assets/nsrh/mdkr.svg') },
                { name: 'Kundwa Health', logo: require('../assets/nsrh/kundwa.svg') },
                { name: 'Centres4Her', logo: require('../assets/nsrh/centres.svg') },

                { name: 'Flambeau', logo: require('../assets/nsrh/flambeau.svg') },
                { name: 'Urukundo Initiative', logo: require('../assets/nsrh/urukundo.svg') },
                { name: 'Vision IT', logo: require('../assets/nsrh/vision.svg') },
                { name: 'MobiKlinic', logo: require('../assets/nsrh/mobi.svg') },
                { name: 'Kiakia Health', logo: require('../assets/nsrh/kiakia.svg') },
                
            ],
            isVisible: false
        };

    },

    mounted() {
        this.initiativeAnimations();

        if (this.$route.hash === '#startupVentDiv') {
      this.$nextTick(() => {
        const element = document.querySelector('#startupVentDiv');
        if (element) {
          setTimeout(() => {
            element.scrollIntoView({
              behavior: 'auto',
              block: 'start'
            });
          }, 500);
        }
      });
    }
  
    },


    methods: {

        initiativeAnimations() {

            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.isVisible = true;
                    }
                });
            });

            observer.observe(this.$refs.observerElement);
        },
    }
}
</script>

<style>
.fade-in {
    opacity: 0;
    transition: opacity 1.2s ease-in-out;
}

.fade-in-visible {
    opacity: 1;
}

.slide-up-enter-active,
.slide-left-enter-active,
.slide-right-enter-active {
    transition: all 0.7s ease-out;
}

.slide-up-enter-from {
    opacity: 0;
    transform: translateY(50px);
}

.slide-up-enter-to {
    opacity: 1;
    transform: translateY(0);
    /* Ensure Y-axis is reset to 0 */
}

.slide-left-enter-from {
    opacity: 0;
    transform: translateX(-50px);
}

.slide-left-enter-to {
    opacity: 1;
    transform: translateX(0);
    /* Reset X-axis */
}

.slide-right-enter-from {
    opacity: 0;
    transform: translateX(50px);
}

.slide-right-enter-to {
    opacity: 1;
    transform: translateX(0);
    /* Reset X-axis */
}

/* General Fade */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.6s ease-in-out;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
    {
    opacity: 0;
}

/* Fade and Slide Up */
.fade-slide-up-enter-active {
    transition: all 1s cubic-bezier(0.42, 0, 0.58, 1);
}

.fade-slide-up-enter-from {
    opacity: 0;
    transform: translateY(50px);
}

.fade-slide-up-enter-to {
    opacity: 1;
    transform: translateY(0);
}

/* Fade and Slide Left */
.fade-slide-left-enter-active {
    transition: all 1s cubic-bezier(0.42, 0, 0.58, 1);
    /* Even slower and more natural */
}

.fade-slide-left-enter-from {
    opacity: 0;
    transform: translateX(-80px);
}

.fade-slide-left-enter-to {
    opacity: 1;
    transform: translateX(0);
}

/* Fade and Slide Right */
.fade-slide-right-enter-active {
    transition: all 1s cubic-bezier(0.42, 0, 0.58, 1);
    /* Even slower and more natural */
}

.fade-slide-right-enter-from {
    opacity: 0;
    transform: translateX(80px);
}

.fade-slide-right-enter-to {
    opacity: 1;
    transform: translateX(0);
}

/* Zoom In */
.zoom-enter-active {
    transition: all 0.7s ease;
}

.zoom-enter-from {
    opacity: 0;
    transform: scale(0.9);
}

.zoom-enter-to {
    opacity: 1;
    transform: scale(1);
}

.fade-slide-up-enter-active,
.fade-slide-up-leave-active {
    transition: opacity 1.1s ease-in-out, transform 1.1s ease-in-out;
}

.fade-slide-up-enter-from {
    opacity: 0;
    transform: translateY(50px);
}

.fade-slide-up-enter-to {
    opacity: 1;
    transform: translateY(0);
}

.fade-slide-up-leave-from {
    opacity: 1;
    transform: translateY(0);
}

.fade-slide-up-leave-to {
    opacity: 0;
    transform: translateY(80px);
}
</style>