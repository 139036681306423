<template>
    <h1>Pitch FAQs here!</h1>
</template>

<script>
  export default {
    name: "PitchFaqPage",

  }
</script>

<style>

</style>