export const ventureDetails = [

    //  startups descriptions.................
    {
        name: 'AASAP',
        description: 'AASAP is a unique, transformative tele-health abortion technology and the first of its kind in Rwanda. They aim to end mortality and morbidity resulting from unsafe abortions by expanding legal abortion access through telehealth. Through their AASAPFinder platform they offer a safe, legal, and acceptable channel that effectively guides and connects girls and young women to health facilities where they can specifically access legal safe abortion services without delays, stigma, judgement, or discrimination, guided by the Rwandan ministerial order on safe abortion.',
        country: 'Rwanda',
        logo: require('../src/assets/nsrh/aasap-w.svg'),
    },
    {
        name: 'ARAME',
        description: 'Arame provides sexual and reproductive health (SRH) services and information through their Interactive Voice Response (IVR) solution platform, which is accessible via a toll-free number. This platform is available on both smartphones and non-smartphones, providing a user-friendly interface through voice interactions. This approach makes SRH information and services, including counselling and referral consulting, more accessible to a broader population in Rwanda, including those with limited literacy or technological proficiency.',
        country: 'Rwanda',
        logo: require('../src/assets/nsrh/arame-w.svg'),
    },
    {
        name: 'DawaHealth',
        description: 'Dawa Health, delivers maternal healthcare through offline and online solutions including a fleet of mobile clinic vans that make maternal healthcare accessible to women in marginalised areas in Zambia, the DawaMom AI-powered app that provides tailored maternal healthcare services, a hybrid retail pharmacy selling SRH products like birth kits with delivery services available through e-bikes addressing transportation barriers in marginalised communities and by leveraging a network of underutilised healthcare workforce, including clinical officers, midwives, and Community Health Workers (CHWs), empowering them to be the frontline healthcare providers and provide maternal health and other SRHR services.',
        country: 'Zambia',
        logo: require('../src/assets/nsrh/dawa-w.svg'),
    },
    {
        name: 'GRIT',
        description: 'GRIT’s app and AI-powered chatbot targets both men and women who are victims of SGBV, in South Africa. Their app also Includes panic buttons to call emergency services, collect legal evidence and access medical services. Kwanele was designed in close collaboration with victims of SGBV, including those who are often marginalised, such as sex workers and men. They bring a holistic approach to the problem by supporting emergencies, as well as building a legal case.',
        country: 'South Africa',
        logo: require('../src/assets/nsrh/grit-w.svg'),
    },
    {
        name: 'JoCare',
        description: 'Through their telehealth web platform, app, toll-free line, braille board games, and YouTube channel, Jocare offers personalized SRH content, interactive tools, and confidential support. The solution stands out in the Rwandan context as one of its target groups is People with Disabilities and their solution makes accommodations for the needs of People with Disabilities. The platform aims to empower individuals with SRH knowledge and support, breaking down barriers in Rwandas SRH landscape.',
        country: 'Rwanda',
        logo: require('../src/assets/nsrh/jocare-w.svg'),
    },
    {
        name: 'LUNA',
        description: 'LUNA addresses the gap in accessible, comprehensive healthcare and wellness services for women, particularly during crucial life stages such as menstruation, pregnancy, and menopause. Through their digital platform, LUNA, empowers women to take charge of their health by providing an informed, supportive ecosystem for those facing sexual and reproductive health (SRH) challenges. It offers features like online consultations, personalised care plans, and community support.',
        country: 'Rwanda',
        logo: require('../src/assets/nsrh/luna-w.svg'),
    },
    {
        name: 'Malaica',
        description: 'Malaica is a remote midwife-led online program that addresses high maternal mortality rates. The program works by matching pregnant women with a registered nurse-midwife who supports them throughout pregnancy. The interaction between the pregnant woman and the Malaica nurse-midwife is done via WhatsApp (chat, audio or video calls). The program also supports mothers who have not carried the baby to term with counseling services.',
        country: 'Kenya',
        logo: require('../src/assets/nsrh/malaica-w.svg'),
    },
    {
        name: 'SMA',
        description: 'Utilising the Safe Motherhood app, they provide innovative, affordable technology for pregnant women and bolster healthcare workforce capacity through mobile-based education on regular contraceptive use The platform profiles and supports women via digital tools on smartphones, generally focusing on user-friendly technology respecting maternal care norms.',
        country: 'Zambia',
        logo: require('../src/assets/nsrh/mother-w.svg'),
    },
    {
        name: 'T.T.Y.M Global',
        description: 'Talk to Your Midwife is working towards reducing maternal mortality rates in Ghana and empowering women and girls to take charge of their reproductive health through their mobile app. The app facilitates secure online midwife consultations, providing women with access to expert advice and ensuring timely and personalized guidance on reproductive health matters. This not only breaks down geographical barriers but also addresses the shortage of healthcare professionals in certain areas. The app also allows women and girls to track pregnancy, menstrual cycles, and fertility. Additionally, Talk to Your Midwife offers offline SRH solutions, such as selling emergency maternity kits with birthing essentials to pregnant women and midwives. They also train midwives on safe delivery practices and onboard them onto their digital platform to provide their services to users.',
        country: 'Ghana',
        logo: require('../src/assets/nsrh/talk-w.svg'),
    },
    {
        name: 'Natal Cares',
        description: 'Natal Cares is combating maternal mortality in Nigeria by addressing various unhealthy child-delivery practices in underserved communities facilitated by untrained Traditional Birth Attendants and other Local Community Caregivers. The organisation is also working to bridge the basic healthcare information gap in the local communities, where naïve women are vulnerable to superstitions and fables regarding pregnancy and childbirth. Natal Cares achieves this through the sale of comprehensive delivery kits containing 16 W.H.O. recommended critical delivery supplies, a radio program that provides maternal health information to women in rural areas, and mobile messaging with personalized weekly texts/voice notes with antenatal and postnatal information.',
        country: 'Nigeria',
        logo: require('../src/assets/nsrh/natal-w.svg'),
    },
    {
        name: 'TenaSeb',
        description: 'An online platform for women to seek medical advice and information, anonymously and professionally. Includes anonymous consultations, telehealth expertise and education. They offer consultations in multiple languages, host SRH educational content such as Video tutorials, articles, and interactive tools on YouTube as part of its presence. TenaSeb is revolutionising the approach to addressing the identified sexual and reproductive health (SRH) challenges, leveraging innovation and technology to provide accessible and confidential solutions.',
        country: 'Ethiopia',
        logo: require('../src/assets/nsrh/tena-w.svg'),
    },
    {
        name: 'SophieBOT',
        description: 'An Artificial intelligence chatbot that is fed with verified information on sexuality and sexual reproductive health and relays the information to its users through conversations that are driven by text or voice chats. Its features include anonymous forums and digital chatbots built-in on the app.',
        country: 'Kenya',
        logo: require('../src/assets/nsrh/sophie-w.svg'),
    },
    {
        name: 'MDKrLabs',
        description: 'MDKr Labs is a telemedicine platform for SRH questions, including a platform to store the medical history of each individual, along with advisory services dedicated to SRH using offline technologies including USSD technologies, SMS and voice messages in local languages.',
        country: 'Togo',
        logo: require('../src/assets/nsrh/mdkr-w.svg'),
    },
    {
        name: 'Kundwa Health',
        description: 'Through their app, Kundwa Health addresses taboos around SRH prevalent in Rwanda by providing young people with evidence-based information approved by specialists. The app facilitates online consultations between young people and doctors, ensuring they receive accurate and reliable SRH information. Additionally, Kundwa Health complements their online solution with an offline solution: Youth Corners, which are interactive spaces for young people to come together, discuss SRH-related issues, and access reliable SRH information.',
        country: 'Rwanda',
        logo: require('../src/assets/nsrh/kundwa-w.svg'),
    },
    {
        name: 'Centres4Her',
        description: 'Centres4Her, provides a one-stop shop for services that survivors of violence need, including medical care, psychosocial support, legal aid, and shelter. They use geolocation to provide users with a list of nearby service providers in a 10km radius. They also have a confidential app that does not track or store any personal information about its users. This is essential for survivors who may be afraid of retaliation from their abuser.',
        country: 'Uganda',
        logo: require('../src/assets/nsrh/centres-w.svg'),
    },
    {
        name: 'Flambeau',
        description: 'Flambeau Ivoire leverages a website and mobile application to offer a platform for healthcare workers to provide better information to patients on SRH. They serve as platforms for patients to book appointments online for consultations and follow-ups. They also improve access to contraceptives through distribution.',
        country: 'Cote d’Ivoire',
        logo: require('../src/assets/nsrh/flambeau-w.svg'),
    },
    {
        name: 'Urukundo Initiative',
        description: 'Urukundo is a youth led social enterprise committed to revolutionizing Life Skills especially Sexual Reproductive Health and Mental Health education in Africa through play based innovations. They offer the globally acclaimed URUKUNDO Life Skills BOARD GAME—accessible physically and digitally. This award-winning game provides evidence-based Life Skills information in a friendly, interactive, and inclusive manner for young people worldwide.',
        country: 'Rwanda',
        logo: require('../src/assets/nsrh/urukundo-w.svg'),
    },
    {
        name: 'Vision IT',
        description: 'Vision IT leverages a website and a mobile application to offer SRH-related information. Provides telehealth and products for SRH Niger, tailored to specific socio-cultural barriers and challenges, i.e. early marriage. It is breaking the mold because of the context of its operation rather than its app. Vision IT contributes to a context where there has been limited SRH investment in the past, but there is a significant need. They bring together expertise on SRHR, as well as strong technology insights, to tailor information and care to the context.',
        country: 'Niger',
        logo: require('../src/assets/nsrh/vision-w.svg'),
    },
    {
        name: 'MobiKlinic',
        description: 'Driven by a deep-rooted commitment to healthcare equity, they are revolutionizing last-mile healthcare delivery through a fusion of digital and physical approaches. Through an online eLearning platform, they train community health professionals on key health issues, particularly those that pertain to women, such as maternal health, family planning, and sexual reproductive health, with the aim of empowering individuals to access quality SRH healthcare, seamlessly receive care, and gain essential SRH information.',
        country: 'Uganda',
        logo: require('../src/assets/nsrh/mobi-w.svg'),
    },
    {
        name: 'KiaKia Health',
        description: 'Kia Kia, employs a user-friendly web application for comprehensive SRH information and virtual consultations, overcoming geographical barriers in Nigeria. They also have veiled clinics in accessible locations that provide private spaces for women, offering specialised services and mitigating cultural stigma. The Kiakia School Ambassador Program, targeting adolescents and young people, establishes relatable resources in tertiary institutions to foster safe discussions on sexual and reproductive health, encouraging young women to seek help.',
        country: 'Nigeria',
        logo: require('../src/assets/nsrh/kiakia-w.svg'),
    },

]
