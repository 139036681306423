<template>
  <!-- first section -->
  <div class="flex flex-col lg:flex-row  mt-24 lg:mt-12 lg:mt-32 px-6 w-full lg:space-x-4 " ref="observerElement">
    <!-- Text Section -->
     <transition  name="fade-slide-left" mode="out-in" appear v-if="isVisible">
    <div class="lg:text-left  space-y-4 w-3/5">
      <p class="text-3xl lg:text-7xl font-rockinsoda text-navy">FUELING</p>
      <p class="text-3xl lg:text-7xl font-rockinsoda text-navy"> INNOVATION</p>


    </div>
  </transition>
  
    <!-- Description and Button Section -->
    <transition  name="fade-slide-up" mode="out-in" appear v-if="isVisible">

    <div class="text-justify mt-4 lg:mt-0 space-y-8 w-full lg:w-10/12">
      <p class="text-base lg:text-2xl font-gramatika text-navy">
        Hanga Pitchfest brings the most influential experts together who help in selecting the best 
        innovative ventures. These seasoned professionals provide invaluable insights and guidance,
        ensuring that the most promising startups receive the recognition and support they deserve.
      </p>
     
    </div>
    </transition>
  </div>
  
    <!-- 2023 judges -->
     
    <div class="ml-12 mt-32 flex space-x-2">
      <p class="font-gramatika text-lg">Judges</p>
      <hr class="w-[20px] border border-black mt-4 ">
      <p class="font-gramatika text-lg" >2024</p>
     </div>
    

     <div
     class="relative overflow-x-auto flex  whitespace-nowrap scrollbar-hide mt-10 ml-0 -mb-44 lg:ml-12"
     ref="scrollContainer"
     style="height: 800px;"
     @touchstart="handleTouchStart"
     @touchmove="handleTouchMove"
   >        <div class="relative h-[582px] w-[400px] flex-shrink-0">
          <img src="../assets/CEO Regis.jpg" class="w-full h-full object-cover">
          <div class="absolute inset-0" style="background: linear-gradient(to bottom, rgba(1, 9, 48, 0.05) 0%, rgba(1, 9, 48, 0.2) 50%, rgba(1, 9, 48, 0.8) 100%);"></div>
          <div class="text-4xl font-rockinsoda text-white absolute inset-0 flex items-center justify-center">
            <div class="space-y-4 ">
              <div class=" mt-80">
                <p class="text-center">
                  Regis RUGEMANSHURO
                </p>
              </div>
  
              <div class="">
                <p class="text-white font-gramatika text-xl text-center"> CEO </p>
                <p class="text-white font-gramatika text-xl text-center"> RSSB</p>
              </div>
            </div> 
  
          </div>                
           </div>
        
        
        <div class="relative h-[582px] w-[400px] flex-shrink-0">
          <img src="../assets/conard.jpg" class="w-full h-full object-cover">
          <div class="absolute inset-0" style="background: linear-gradient(to bottom, rgba(1, 9, 48, 0.02) 0%, rgba(1, 9, 48, 0.15) 50%, rgba(1, 9, 48, 0.9) 100%);"></div>
          <div class="text-4xl font-rockinsoda text-white absolute inset-0 flex items-center justify-center">
            <div class="space-y-4 ">
              <div class=" mt-80">
                <p class="text-center">
                  Conard Tucker  
                </p>
              </div>
  
              <div class="">
                <p class="text-white font-gramatika text-center text-xl"> Director </p>
                <p class="text-white font-gramatika text-xl text-center"> CMU-Africa</p>
              </div>
            </div> 
  
          </div>        
                 
        </div>
        
        
        <div class="relative h-[582px] w-[400px] flex-shrink-0">
          <img src="../assets/New Headshot.jpg" class="w-full h-full object-cover">
          <div class="absolute inset-0" style="background: linear-gradient(to bottom, rgba(1, 9, 28, 0.02) 0%, rgba(1, 9, 28, 0.15) 30%, rgba(1, 9, 28, 0.8) 100%);"></div>
          <div class="text-4xl font-rockinsoda text-white absolute inset-0 flex items-center justify-center">
            <div class="space-y-4 ">
              <div class=" mt-80">
                <p class="text-center">
                  Christelle Kwizera
                </p>
              </div>
  
              <div class="">
                
                <p class="text-white font-gramatika text-xl text-center"> Founder of Water Access Rwanda</p>
              </div>
            </div> 
  
          </div>
  
          
        </div>
  
        <div class="relative h-[582px] w-[400px] flex-shrink-0">
          <img src="../assets/PS Antoine.jpg" class="w-full h-full object-cover">
          <div class="absolute inset-0" style="background: linear-gradient(to bottom, rgba(1, 9, 28, 0.02) 0%, rgba(1, 9, 28, 0.15) 30%, rgba(1, 9, 28, 0.8) 100%);"></div>
          <div class="text-4xl font-rockinsoda text-white absolute inset-0 flex items-center justify-center">
            <div class="space-y-4 ">
              <div class=" mt-80">
                <p class="text-center">
                  Antoine Marie KAJANGWE

                </p>
              </div>
  
              <div class="">
                <p class="text-white font-gramatika text-xl text-center ">Permanent Secretary </p>
                <p class="text-white font-gramatika text-xl text-center">Ministry of Trade and Industry</p>
              </div>
            </div> 
  
          </div>
  
          
        </div>
        
        
        
      </div>
  
     
    <div class="ml-12 mt-32 flex space-x-2">
      <p class="font-gramatika text-lg">Judges</p>
      <hr class="w-[20px] border border-black mt-4 ">
      <p class="font-gramatika text-lg" >2023</p>
     </div>
    

     <div
     class="relative overflow-x-auto flex  whitespace-nowrap scrollbar-hide mt-10 ml-0 -mb-44 lg:ml-12"
     ref="scrollContainer"
     style="height: 800px;"
     @touchstart="handleTouchStart"
     @touchmove="handleTouchMove"
   >        <div class="relative h-[582px] w-[400px] flex-shrink-0">
          <img src="../assets/alice.jpg" class="w-full h-full object-cover">
          <div class="absolute inset-0" style="background: linear-gradient(to bottom, rgba(1, 9, 48, 0.05) 0%, rgba(1, 9, 48, 0.2) 50%, rgba(1, 9, 48, 0.8) 100%);"></div>
          <div class="text-4xl font-rockinsoda text-white absolute inset-0 flex items-center justify-center">
            <div class="space-y-4 ">
              <div class=" mt-80">
                <p class="text-center">
                  Alice Nkulikiyinka
                </p>
              </div>
  
              <div class="">
                <p class="text-white font-gramatika text-xl text-center"> Country director </p>
                <p class="text-white font-gramatika text-xl text-center"> BPN Rwanda</p>
              </div>
            </div> 
  
          </div>                
           </div>
        
        
        <div class="relative h-[582px] w-[400px] flex-shrink-0">
          <img src="../assets/conard.jpg" class="w-full h-full object-cover">
          <div class="absolute inset-0" style="background: linear-gradient(to bottom, rgba(1, 9, 48, 0.02) 0%, rgba(1, 9, 48, 0.15) 50%, rgba(1, 9, 48, 0.9) 100%);"></div>
          <div class="text-4xl font-rockinsoda text-white absolute inset-0 flex items-center justify-center">
            <div class="space-y-4 ">
              <div class=" mt-80">
                <p class="text-center">
                  Conard Tucker  
                </p>
              </div>
  
              <div class="">
                <p class="text-white font-gramatika text-center text-xl"> Director </p>
                <p class="text-white font-gramatika text-xl text-center"> CMU-Africa</p>
              </div>
            </div> 
  
          </div>        
                 
        </div>
        
        
        <div class="relative h-[582px] w-[400px] flex-shrink-0">
          <img src="../assets/eleni.jpg" class="w-full h-full object-cover">
          <div class="absolute inset-0" style="background: linear-gradient(to bottom, rgba(1, 9, 28, 0.02) 0%, rgba(1, 9, 28, 0.15) 30%, rgba(1, 9, 28, 0.8) 100%);"></div>
          <div class="text-4xl font-rockinsoda text-white absolute inset-0 flex items-center justify-center">
            <div class="space-y-4 ">
              <div class=" mt-80">
                <p class="text-center">
                  ELENI GABRE-MADHIN
                </p>
              </div>
  
              <div class="">
                <p class="text-white font-gramatika text-xl text-center ">Chief Innovation Officer</p>
                <p class="text-white font-gramatika text-xl text-center">UNDP Regional Bureau for Africa </p>
              </div>
            </div> 
  
          </div>
  
          
        </div>
  
        <div class="relative h-[582px] w-[400px] flex-shrink-0">
          <img src="../assets/nicole.jpg" class="w-full h-full object-cover">
          <div class="absolute inset-0" style="background: linear-gradient(to bottom, rgba(1, 9, 28, 0.02) 0%, rgba(1, 9, 28, 0.15) 30%, rgba(1, 9, 28, 0.8) 100%);"></div>
          <div class="text-4xl font-rockinsoda text-white absolute inset-0 flex items-center justify-center">
            <div class="space-y-4 ">
              <div class=" mt-80">
                <p class="text-center">
                  NICOLE DAVIS
                </p>
              </div>
  
              <div class="">
                <p class="text-white font-gramatika text-xl text-center ">World Bank Ltd</p>
                <p class="text-white font-gramatika text-xl text-center">Partner</p>
              </div>
            </div> 
  
          </div>
  
          
        </div>
        
        
        
      </div>
  
      <!-- 2022 judges  -->
      <div class="ml-12 mt-32 flex space-x-2">
        <p class="font-gramatika text-lg">Judges</p>
        <hr class="w-[20px] border border-black mt-4 ">
        <p class="font-gramatika text-lg" >2022</p>
       </div>
    
       <div
       class="relative overflow-x-auto flex  whitespace-nowrap scrollbar-hide mt-10 ml-0 -mb-44 lg:ml-12"
       ref="scrollContainer"
       style="height: 800px;"
       @touchstart="handleTouchStart"
       @touchmove="handleTouchMove"
     >          <div class="relative h-[582px] w-[400px] flex-shrink-0">
            <img src="../assets/obina.jpg" class="w-full h-full object-cover">
            <div class="absolute inset-0" style="background: linear-gradient(to bottom, rgba(1, 9, 48, 0.05) 0%, rgba(1, 9, 48, 0.2) 50%, rgba(1, 9, 48, 0.8) 100%);"></div>
            <div class="text-4xl font-rockinsoda text-white absolute inset-0 flex items-center justify-center">
              <div class="space-y-4 ">
                <div class=" mt-80">
                  <p class="text-center">
                    OBINNA UKUANI 
                  </p>
                </div>
    
                <div class="">
                  <p class="text-white font-gramatika text-xl text-center">Chief Digital Officer </p>
                  <p class="text-white font-gramatika text-xl text-center">Bank of Kigali Plc</p>
                </div>
              </div> 
    
            </div>                
             </div>
          
          
          <div class="relative h-[582px] w-[400px] flex-shrink-0">
            <img src="../assets/hortense.jpg" class="w-full h-full object-cover">
            <div class="absolute inset-0" style="background: linear-gradient(to bottom, rgba(1, 9, 48, 0.02) 0%, rgba(1, 9, 48, 0.15) 50%, rgba(1, 9, 48, 0.9) 100%);"></div>
            <div class="text-4xl font-rockinsoda text-white absolute inset-0 flex items-center justify-center">
              <div class="space-y-4 ">
                <div class=" mt-80">
                  <p class="text-center">
                    HORTENSE MUDENGE 
                  </p>
                </div>
    
                <div class="">
                  <p class="text-white font-gramatika text-center text-xl"> Chief Operations Officer </p>
                  <p class="text-white font-gramatika text-xl text-center"> Rwanda Finance Ltd</p>
                </div>
              </div> 
    
            </div>        
                   
          </div>
          
          
          <div class="relative h-[582px] w-[400px] flex-shrink-0">
            <img src="../assets/eric.jpg" class="w-full h-full object-cover">
            <div class="absolute inset-0" style="background: linear-gradient(to bottom, rgba(1, 9, 28, 0.02) 0%, rgba(1, 9, 28, 0.15) 30%, rgba(1, 9, 28, 0.8) 100%);"></div>
            <div class="text-4xl font-rockinsoda text-white absolute inset-0 flex items-center justify-center">
              <div class="space-y-4 ">
                <div class=" mt-80">
                  <p class="text-center">
                    ERIC KACOU
                  </p>
                </div>
    
                <div class="">
                  <p class="text-white font-gramatika text-xl text-center ">Ceo & Co-founder </p>
                  <p class="text-white font-gramatika text-xl text-center">ESPartners </p>
                </div>
              </div> 
    
            </div>
    
            
          </div>
    
          <div class="relative h-[582px] w-[400px] flex-shrink-0">
            <img src="../assets/alice.jpg" class="w-full h-full object-cover">
            <div class="absolute inset-0" style="background: linear-gradient(to bottom, rgba(1, 9, 48, 0.05) 0%, rgba(1, 9, 48, 0.2) 50%, rgba(1, 9, 48, 0.8) 100%);"></div>
            <div class="text-4xl font-rockinsoda text-white absolute inset-0 flex items-center justify-center">
              <div class="space-y-4 ">
                <div class=" mt-80">
                  <p class="text-center">
                    Alice Nkulikiyinka
                  </p>
                </div>
    
                <div class="">
                  <p class="text-white font-gramatika text-xl text-center"> Country director </p>
                  <p class="text-white font-gramatika text-xl text-center"> BPN Rwanda</p>
                </div>
              </div> 
    
            </div>                
             </div>
          
          
          
        </div>
  
        <!-- 2021 judges -->
        <div class="ml-12 mt-32 flex space-x-2">
          <p class="font-gramatika text-lg">Judges</p>
          <hr class="w-[20px] border border-black mt-4 ">
          <p class="font-gramatika text-lg" >2021</p>
         </div>
      
                 
         <div
         class="relative overflow-x-auto flex  whitespace-nowrap scrollbar-hide mt-10 ml-0 -mb-44 lg:ml-12"
         ref="scrollContainer"
         style="height: 800px;"
         @touchstart="handleTouchStart"
         @touchmove="handleTouchMove"
       >            <div class="relative h-[582px] w-[400px] flex-shrink-0">
              <img src="../assets/clarisse.jpg" class="w-full h-full object-cover">
              <div class="absolute inset-0" style="background: linear-gradient(to bottom, rgba(1, 9, 48, 0.05) 0%, rgba(1, 9, 48, 0.2) 50%, rgba(1, 9, 48, 0.8) 100%);"></div>
              <div class="text-4xl font-rockinsoda text-white absolute inset-0 flex items-center justify-center">
                <div class="space-y-4 ">
                  <div class=" mt-80">
                    <p class="text-center">
                      CLARISSE IRIBAGIZA 
                    </p>
                  </div>
      
                  <div class="">
                    <p class="text-white font-gramatika text-xl text-center">Chief Executive Officer </p>
                    <p class="text-white font-gramatika text-xl text-center">Hehe</p>
                  </div>
                </div> 
              </div> 
              </div>       
            
            
            <div class="relative h-[582px] w-[400px] flex-shrink-0">
              <img src="../assets/sena.jpg" class="w-full h-full object-cover">
              <div class="absolute inset-0" style="background: linear-gradient(to bottom, rgba(1, 9, 48, 0.02) 0%, rgba(1, 9, 48, 0.15) 50%, rgba(1, 9, 48, 0.9) 100%);"></div>
              <div class="text-4xl font-rockinsoda text-white absolute inset-0 flex items-center justify-center">
                <div class="space-y-4 ">
                  <div class=" mt-80">
                    <p class="text-center">
                      PRO SENAIT FISSEHA 
                    </p>
                  </div>
      
                  <div class="">
                    <p class="text-white font-gramatika text-center text-xl"> Director of Global Programs</p>
                      
                    <p class="text-white font-gramatika text-xl text-center"> The Susan Thompson Buffett Foundation </p>
                  </div>
                </div> 
      
              </div>        
                     
            </div>
            
            
            <div class="relative h-[582px] w-[400px] flex-shrink-0">
              <img src="../assets/Ron.jpg" class="w-full h-full object-cover">
              <div class="absolute inset-0" style="background: linear-gradient(to bottom, rgba(1, 9, 28, 0.02) 0%, rgba(1, 9, 28, 0.15) 30%, rgba(1, 9, 28, 0.8) 100%);"></div>
              <div class="text-4xl font-rockinsoda text-white absolute inset-0 flex items-center justify-center">
                <div class="space-y-4 ">
                  <div class=" mt-80">
                    <p class="text-center">
                      RON FISHER 
                    </p>
                  </div>
      
                  <div class="">
                    <p class="text-white font-gramatika text-xl text-center "> Director, Strategic Product Growth </p>
                    <p class="text-white font-gramatika text-xl text-center"> Mindbody  </p>
                  </div>
                </div> 
      
              </div>
      
              
            </div>
            <div class="relative h-[582px] w-[400px] flex-shrink-0">
              <img src="../assets/eric.jpg" class="w-full h-full object-cover">
              <div class="absolute inset-0" style="background: linear-gradient(to bottom, rgba(1, 9, 28, 0.02) 0%, rgba(1, 9, 28, 0.15) 30%, rgba(1, 9, 28, 0.8) 100%);"></div>
              <div class="text-4xl font-rockinsoda text-white absolute inset-0 flex items-center justify-center">
                <div class="space-y-4 ">
                  <div class=" mt-80">
                    <p class="text-center">
                      ERIC KACOU
                    </p>
                  </div>
      
                  <div class="">
                    <p class="text-white font-gramatika text-xl text-center ">Ceo & Co-founder </p>
                    <p class="text-white font-gramatika text-xl text-center">ESPartners </p>
                  </div>
                </div> 
      
              </div>
      
              
            </div>
            
            
            
          </div>

            <!-- next section -->

            <div class="ml-4 md:ml-12 mt-12 md:mt-32">
              <div class="mt-8 md:mt-12 md:ml-16 overflow-hidden">
                <div ref="videoWrapper" class="flex space-x-4 md:space-x-8 overflow-x-auto scroll-smooth">
                  <!-- First text Block -->
                  <div class="flex-shrink-0   flex  flex-col md:flex-row space-y-2 w-[300px] h-[400px] md:w-[600px] md:h-[600px]">
                    <div class="relative flex flex-col md:flex-row w-full h-full">
                      <div class="mt-8 md:mt-24">
                        <span class="font-rockinsoda text-tangerine text-5xl md:text-7xl ml-2">"</span>
                        <p class="text-navy text-lg md:text-2xl font-gramatika font-bold w-[40vh] md:w-[60vh] -mt-6 md:-mt-8 p-2 md:p-4 text-center">
                          With funding from Hanga, I was able to launch for our first international market.
                        </p>
                        <div class="flex space-x-2 ml-2">
                          <hr class="w-[30px] md:w-[50px] border-2 mt-2 border-navy">
                          <p class="font-rockinsoda text-navy text-lg md:text-2xl">THERESA, DOPE INITIATIVES</p>
                        </div>
                      </div>
                      <div class="flex-shrink-0 h-[300px]  md:h-[500px]">
                        <img src="../assets/dope.jpg" class="w-full h-full object-cover">
                      </div>
                    </div>
                  </div>
                </div>
            
                <!-- Scroll Buttons -->
                <div class="flex justify-between items-center -mt-12 md:-mt-24 space-x-2 pr-4 md:pr-12">
                  <button @click="scrollLeft" 
                          class="border-2 rounded-full p-3 md:p-4 bg-white text-navy focus:outline-none hover:bg-navy hover:text-white transition">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4 md:w-6 md:h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                    </svg>
                  </button>
                  
                  <button @click="scrollRight" 
                          class="border-2 rounded-full p-3 md:p-4 bg-white text-navy focus:outline-none hover:bg-navy hover:text-white transition">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4 md:w-6 md:h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
             
    
      
    
  
  </template>
  
  
  <script>
  export default{
      name:'JudgesPage',

      data(){

         return{
          isVisible:false,
          isVisible2:false,
         }
      },

      mounted(){

      const handleMouseMove = (event) => {
      if (event.buttons) {
        this.$refs.scrollContainer.scrollLeft -= event.movementX; 
      }
    };
    
    this.$refs.scrollContainer.addEventListener('mousemove', handleMouseMove);
    
    // Cleanup
    this.$refs.scrollContainer.addEventListener('mouseleave', () => {
      this.$refs.scrollContainer.removeEventListener('mousemove', handleMouseMove);
    });

    this.initiativeAnimations();

      },

      methods: {
    handleTouchStart(event) {
      this.startX = event.touches[0].clientX;
    },
    handleTouchMove(event) {
      const distance = this.startX - event.touches[0].clientX;
      this.$refs.scrollContainer.scrollLeft += distance;
      this.startX = event.touches[0].clientX;
    },

    missoutAnimations(){
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.isVisible1 = true;
      }
    });
  });

  observer.observe(this.$refs.observerElement1);
},

initiativeAnimations() {
  
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.isVisible = true;
      }
    });
  });

  observer.observe(this.$refs.observerElement);
},

  },

  beforeUnmount() {
    this.$refs.scrollContainer.removeEventListener('mousemove', this.handleMouseMove);
  }
  }
  </script>

<style>
  .scrollbar-hide {
    scrollbar-width: none;
  }
  .scrollbar-hide::-webkit-scrollbar {
    display: none; 
  }


.fade-in {
  opacity: 0;
  transition: opacity 1.2s ease-in-out;
}

.fade-in-visible {
  opacity: 1;
}

.slide-up-enter-active,
.slide-left-enter-active,
.slide-right-enter-active {
  transition: all 0.7s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(50px);
}

.slide-up-enter-to {
  opacity: 1;
  transform: translateY(0); /* Ensure Y-axis is reset to 0 */
}

.slide-left-enter-from {
  opacity: 0;
  transform: translateX(-50px);
}

.slide-left-enter-to {
  opacity: 1;
  transform: translateX(0); /* Reset X-axis */
}

.slide-right-enter-from {
  opacity: 0;
  transform: translateX(50px);
}

.slide-right-enter-to {
  opacity: 1;
  transform: translateX(0); /* Reset X-axis */
}
/* General Fade */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s ease-in-out;
}
.fade-enter, 
.fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

/* Fade and Slide Up */
.fade-slide-up-enter-active {
  transition: all 1s cubic-bezier(0.42, 0, 0.58, 1);}

.fade-slide-up-enter-from {
  opacity: 0;
  transform: translateY(50px);
}
.fade-slide-up-enter-to {
  opacity: 1;
  transform: translateY(0);
}

/* Fade and Slide Left */
.fade-slide-left-enter-active {
  transition: all 1s cubic-bezier(0.42, 0, 0.58, 1); /* Even slower and more natural */
}
.fade-slide-left-enter-from {
  opacity: 0;
  transform: translateX(-80px);
}
.fade-slide-left-enter-to {
  opacity: 1;
  transform: translateX(0);
}

/* Fade and Slide Right */
.fade-slide-right-enter-active {
  transition: all 1s cubic-bezier(0.42, 0, 0.58, 1); /* Even slower and more natural */
}
.fade-slide-right-enter-from {
  opacity: 0;
  transform: translateX(80px);
}
.fade-slide-right-enter-to {
  opacity: 1;
  transform: translateX(0);
}

/* Zoom In */
.zoom-enter-active {
  transition: all 0.7s ease;
}
.zoom-enter-from {
  opacity: 0;
  transform: scale(0.9);
}
.zoom-enter-to {
  opacity: 1;
  transform: scale(1);
}

.fade-slide-up-enter-active,
.fade-slide-up-leave-active {
  transition: opacity 1.1s ease-in-out, transform 1.1s ease-in-out;
}

.fade-slide-up-enter-from {
  opacity: 0;
  transform: translateY(50px);
}

.fade-slide-up-enter-to {
  opacity: 1;
  transform: translateY(0);
}

.fade-slide-up-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.fade-slide-up-leave-to {
  opacity: 0;
  transform: translateY(80px);
}

</style>