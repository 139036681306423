<template>
  <div class="w-full min-h-screen absolute" style="background-color: #FFFFFF;">
    <Navbar />


    <!-- Newsletter signup section -->
    <div>
      <h1>Add a Post</h1>
      <input type="text" v-model="title" placeholder="Title" />
      <textarea v-model="content" placeholder="Content"></textarea>
      <button @click="createPost">Publish</button>
  
      <h2>All Posts</h2>
      <div v-for="post in posts" :key="post.id">
        <h3>{{ post.attributes.title }}</h3>
        <p>{{ post.attributes.content }}</p>
      </div>
    </div>

    <NewsLetter/>
    
    <!-- Footer section -->
    
  
    <FooterPage/>
  </div>
</template>

<script>
import { getPosts, createPost } from '@/services/postServices';
import Navbar from '@/components/Navbar.vue';
import NewsLetter from '@/components/NewsLetter.vue';
import FooterPage from '@/components/footerPage.vue';
export default {
  name: "BlogsPage",
  components: {
    Navbar,
    NewsLetter,
    FooterPage

  },

  data() {
    return {
      title: '',
      content: '',
      posts: [],
    };
  },
  async created() {
    this.posts = await getPosts();
  },
  methods: {
    async createPost() {
      const newPost = { title: this.title, content: this.content };
      await createPost(newPost);
      this.posts = await getPosts(); 
      this.title = '';
      this.content = '';
    },
  },

}
</script>

<style></style>